<template>
    <div>{{ msg }}</div>
</template>
<script>
import dd from 'gdt-jsapi'
const axios = require('axios')

export default {
    data() {
        return {
            msg: '',
            fastActions: {
                got_project: '/api/supervision/got_project/'
            }
        }
    },
    watch: {},
    created() {
        this.$toast.loading({
            message: '登录中...',
            forbidClick: true,
            loadingType: 'spinner'
        })
        sessionStorage.setItem('authConfigComplete', true)
        const { u } = this.$route.query
        if (u !== undefined) {
            axios.post('/api/dd_user/user_login_mock/?user_id=' + u).then((res) => {
                if (res.data.code === 0) {
                    sessionStorage.setItem('userInfo', JSON.stringify(res.data.userInfo))
                    this.afterLogin()
                } else {
                    this.$dialog.alert({
                        message: res.data.msg
                    })
                }
            })
        } else if (process.env.NODE_ENV === 'production') {
            dd.ready(() => {
                // 登录
                dd.getAuthCode()
                    .then((res) => {
                        // console.log('getAuthCode Complete: ', res)
                        axios
                            .post('/api/dd_user/user_login/', {
                                auth_code: res.code
                            })
                            .then((res) => {
                                if (res.data.code === 0) {
                                    sessionStorage.setItem('userInfo', JSON.stringify(res.data.userInfo))
                                    // 鉴权
                                    if (res.data.userInfo.jsapi_ticket.length > 0) {
                                        dd.authConfig({
                                            ticket: res.data.userInfo.jsapi_ticket,
                                            jsApiList: ['getGeolocation']
                                        })
                                            .then(() => {
                                                sessionStorage.setItem('authConfigComplete', true)
                                            })
                                            .catch(() => {
                                                sessionStorage.setItem('authConfigComplete', false)
                                                this.$dialog.alert({
                                                    message: '鉴权失败，将忽略定位功能。请联系开发者。'
                                                })
                                            })
                                            .finally(() => {
                                                this.afterLogin()
                                            })
                                    } else {
                                        this.$dialog.alert({
                                            message: '鉴权失败，将忽略定位功能。请联系开发者。'
                                        })
                                        sessionStorage.setItem('authConfigComplete', false)
                                        this.afterLogin()
                                    }
                                    // this.afterLogin()
                                }
                            })
                            .catch(() => {
                                this.$dialog.alert({
                                    message: '登录失败，请和管理员确认用户数据'
                                })
                            })
                    })
                    .catch((err) => {
                        axios.post('/api/log/', {
                            level: 40,
                            content: {
                                err: err,
                                context: 'dd.getAuthCode'
                            }
                        })
                        this.$dialog.alert({
                            message: '登录失败，请检查网络或重新登陆。'
                        })
                        // console.log('getAuthCode Err:', err)
                    })
            })
        } else {
            this.$dialog.alert({
                message: '既没有u参数也不是发布版本。'
            })
        }
    },
    mounted() {},
    methods: {
        afterLogin() {
            // 增加next参数来登陆后立刻跳转
            const { next, action } = this.$route.query
            if (action !== undefined) {
                const [act, arg] = action.split('-', 2)
                if (act !== undefined && arg !== undefined && this.fastActions[act] !== undefined) {
                    const url = this.fastActions[act]
                    axios
                        .post(url, arg, {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                        .then(() => {
                            return this.$dialog.alert({
                                message: '已确认收到。'
                            })
                        })
                        .catch(() => {
                            this.$dialog
                                .alert({
                                    message: '网络通信失败，请检查网络。'
                                })
                                .then(() => {
                                    dd.closePage().then((res) => {
                                        console.log(res)
                                    })
                                })
                        })
                } else {
                    axios.post('/api/log/', {
                        level: 40,
                        content: {
                            context: JSON.stringify([act, arg])
                        }
                    })
                }
            }
            if (next !== undefined) {
                const nextURL = decodeURIComponent(next)
                // console.log(nextURL)
                this.$router.push(nextURL)
            } else {
                this.$router.push('/sy')
            }
        }
    }
}
</script>
<style></style>
